<template>
  <div>
    <div class="row gy-3">
      <div class="col-12 gy-5">
        <a @click="onAddClick" class="btn btn-success">
          <font-awesome-icon icon="plus" size="lg" />
          Add Submenu
        </a>
      </div>

      <div v-if="item && item.subMenuItems" class="col-6">
        <ol class="list-group list-group-numbered">
          <li
            v-for="(item, index) in item.subMenuItems"
            :key="index"
            class="
              list-group-item
              d-flex
              justify-content-between
              align-items-start
            "
          >
            <div class="ms-2 me-auto">
              <div class="fw-bold">{{ item.text }}</div>
              {{ item.group }}
            </div>
            <div class="ms-2 p-2">
              <a class="icon-item edit-item">
                <font-awesome-icon
                  class="listIcon"
                  icon="cog"
                  size="lg"
                  data-toggle="tooltip"
                  title="Edit"
                  @click="onEditClick(index)"
                />
              </a>
            </div>
            <div class="ms-2 p-2">
              <a class="icon-item delete-item">
                <font-awesome-icon
                  icon="minus-circle"
                  size="lg"
                  data-toggle="tooltip"
                  title="Delete"
                  @click="onDeleteClick(index)"
                />
              </a>
            </div>
          </li>
        </ol>
      </div>
    </div>
    <app-modal-form
      modalId="subMenuFormModal"
      title="Submenu"
      :fields="fields"
      :url="url"
      ref="subMenuFormModal"
      @onClose="refresh"
    />
    <app-modal-delete
      modalId="subMenuDeleteModal"
      title="Submenu"
      :url="url"
      ref="subMenuDeleteModal"
      @onClose="refresh"
    />
  </div>
</template>

<script>
import AppModalForm from '@/components/modals/ModalForm.vue'
import AppModalDelete from '@/components/modals/ModalDelete.vue'

import { SubMenuSchema } from '@/utils/yup'

export default {
  name: 'SubMenuForm',
  components: { AppModalForm, AppModalDelete },
  setup() {
    return {
      schema: SubMenuSchema(),
    }
  },
  computed: {
    fields() {
      return [
        { name: 'text', type: 'text' },
        { name: 'order', type: 'int' },
        { name: 'group', type: 'text' },
      ]
    },
    item() {
      return this.$store.getters.getItem
    },
    currentId() {
      return this.$route.params.id
    },
    menuUrl() {
      return 'komsol/menus/' + this.currentId
    },
    url() {
      return this.menuUrl + '/submenus'
    },
  },

  methods: {
    onAddClick() {
      this.$refs.subMenuFormModal.onOpen(null)
      const length = this.item.subMenuItems.length
      let newOrder = 0
      if (length > 0) {
        newOrder = this.item.subMenuItems[length - 1]?.order + 1
      }
      this.$refs.subMenuFormModal.formData.order = newOrder
    },
    onEditClick(index) {
      const item = Object.assign({}, this.item.subMenuItems[index])
      this.$refs.subMenuFormModal.onOpen(item)
    },
    onDeleteClick(index) {
      const item = Object.assign({}, this.item.subMenuItems[index])
      this.$refs.subMenuDeleteModal.onOpen([item])
    },
    async refresh() {
      await this.$store.dispatch('getItem', this.menuUrl)
    },
  },
}
</script>

<style scoped>
.list-group-item {
  padding-right: 40px;
}
.list-group-item i {
  position: absolute;
  right: 15px;
}
.listIcon {
  font-size: 1.3rem;
}
</style>

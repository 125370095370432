import { createStore } from 'vuex'
import Cookies from 'js-cookie'

const store = new createStore({
  state: {
    page: 'index',
    item: null,
    jwt: null,
    offices: [],
    roles: [],
    loadingPage: true,
    komsol: {
      langs: [],
    },
  },
  getters: {
    hasJwt: (state) => {
      return state.jwt && state.jwt.length > 0
    },
    getJwt: (state) => {
      return state.jwt
    },
    getItem: (state) => {
      return state.item
    },
    offices: (state) => {
      return state.offices.map((x) => ({ value: x, text: x }))
    },
    roles: (state) => {
      return state.roles.map((x) => ({ value: x, text: x }))
    },
    komsol_langs: (state) => {
      return state.komsol.langs.map((x) => ({ value: x.code, text: x.name }))
    },
  },
  mutations: {
    setJwt(state, jwt) {
      state.jwt = jwt
      this.$axios.defaults.headers.common['Authorization'] = 'Bearer ' + jwt
      Cookies.set('jwt', jwt)
    },
    deleteJwt(state) {
      state.jwt = null
      Cookies.remove('jwt')
    },
    setItem(state, item) {
      state.item = item
    },
    setOffices(state, offices) {
      state.offices = offices
    },
    setRoles(state, roles) {
      state.roles = roles
    },
    setKomsolLanguage(state, langs) {
      state.komsol.langs = langs
    },
  },
  actions: {
    async getKomsolTypes(context) {
      const res = await this.$axios.get('komsol/lang')
      context.commit('setKomsolLanguage', res.data)
    },
    async getAllOffices(context) {
      const res = await this.$axios.get('offices/names')
      context.commit('setOffices', res.data)
    },
    async getAllRoles(context) {
      const res = await this.$axios.get('auth/roles/')
      context.commit('setRoles', res.data)
    },
    async getItem(context, url, query = null) {
      const res = await this.$axios.get(url, { params: query })
      context.commit('setItem', res.data)
    },
    login(context, jwt) {
      context.commit('setJwt', jwt)
    },
    logout(context) {
      context.commit('deleteJwt')
    },
  },
})

export default store

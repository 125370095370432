<template>
  <div>
    <p>Dashboard</p>
  </div>
</template>

<script>
export default {
  name: 'Dashboard',
  methods: {},
}
</script>

<template>
  <nav
    id="sidebarMenu"
    class="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse"
  >
    <div class="position-sticky pt-3">
      <ul class="nav flex-column">
        <li class="nav-item">
          <router-link
            :class="['nav-link', { active: $store.state.page === 'index' }]"
            :to="{ name: 'index' }"
            title
          >
            <font-awesome-icon
              icon="th-large"
              size="lg"
              data-toggle="tooltip"
              title="Edit"
              @click="$router.push({ name: 'index' })"
            />
            Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="['nav-link', { active: $store.state.page === 'users' }]"
            :to="{ name: 'users' }"
            title
          >
            <font-awesome-icon
              icon="users"
              size="lg"
              data-toggle="tooltip"
              title="Edit"
              @click="$router.push({ name: 'users' })"
            />
            Users
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="['nav-link', { active: $store.state.page === 'office' }]"
            :to="{ name: 'office' }"
            title
          >
            <font-awesome-icon
              icon="building"
              size="lg"
              data-toggle="tooltip"
              title="Edit"
              @click="$router.push({ name: 'office' })"
            />
            Office
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="[
              'nav-link',
              { active: $store.state.page === 'filebrowser' },
            ]"
            :to="{ name: 'filebrowser' }"
            title
          >
            <font-awesome-icon
              icon="images"
              size="lg"
              data-toggle="tooltip"
              title="Edit"
              @click="$router.push({ name: 'filebrowser' })"
            />
            Filebrowser
          </router-link>
        </li>
      </ul>

      <h6
        class="
          sidebar-heading
          d-flex
          justify-content-between
          align-items-center
          px-3
          mt-4
          mb-1
          text-muted
        "
      >
        <span>Komsol.com</span>
      </h6>

      <ul class="nav flex-column mb-2">
        <li class="nav-item">
          <router-link
            :class="['nav-link', { active: $store.state.page === 'komsol' }]"
            :to="{ name: 'komsol' }"
            title
          >
            <font-awesome-icon
              icon="globe"
              size="lg"
              data-toggle="tooltip"
              title="Edit"
              @click="$router.push({ name: 'komsol' })"
            />
            Komsol Dashboard
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="['nav-link', { active: $store.state.page === 'articles' }]"
            :to="{ name: 'articles' }"
            title
          >
            <font-awesome-icon
              icon="sticky-note"
              size="lg"
              data-toggle="tooltip"
              title="Edit"
              @click="$router.push({ name: 'articles' })"
            />
            Articles
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :class="['nav-link', { active: $store.state.page === 'menus' }]"
            :to="{ name: 'menus' }"
            title
          >
            <font-awesome-icon
              icon="bars"
              size="lg"
              data-toggle="tooltip"
              title="Edit"
              @click="$router.push({ name: 'menus' })"
            />
            Menus
          </router-link>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'TheSidebar',
  data() {
    const mediaQueryList = window.matchMedia('(max-width: 767px)')
    return {
      collapsed: false,
      mediaQueryList: mediaQueryList,
    }
  },
  computed: {
    menuCollapsedClass() {
      return !this.collapsed ? 'label-expanded' : 'd-none'
    },
    sidebarContainerClass() {
      return !this.collapsed ? 'sidebar-expanded ' : 'sidebar-collapsed'
    },
    iconSizeClass() {
      return !this.collapsed ? ' ' : ''
    },
  },
  created() {
    this.mediaQueryList.addEventListener('change', (event) => {
      this.collapsed = this.mediaQueryList.matches
    })
  },
  methods: {
    collapseSidebar() {},
  },
}
</script>

<!--
<style scoped>
.fas {
  font-size: 1em;
}

.label-expanded {
  margin-left: 10px;
  font-weight: 500;
}

.wrapper {
  position: relative;
}

.sidebar {
  position: fixed;
  height: 100%;
  background-color: #333;
  padding: 0;
}
.sidebar ul li a {
  color: #fff;
  display: block;
}
.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 48px; /* Height of navbar */
  height: calc(100vh - 48px);
  padding-top: 0.5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

/* Expanded */
.sidebar-expanded {
  width: 185px;
}

.sidebar-expanded ul li {
  height: 60px;
}

.sidebar-expanded ul li a {
  padding-top: 15px;
  padding-left: 20px;
  font-size: 1.2em;
}

/* Collapsed */
.sidebar-collapsed {
  width: 80px;
}
.sidebar-collapsed ul li {
  height: 80px;
}

.sidebar-collapsed ul li a {
  padding-top: 5px;
  padding-left: 20px;
  font-size: 3em;
}

/* Hover */

.sidebar ul li:hover {
  background: #000;
}
.sidebar ul li a:hover {
  color: #bdb8d7 !important;
}

/*
@media (max-width: 767px) {
}

.sidebar-expanded {
  width: 230px;
}
.sidebar-expanded > ul > a {
  height: 60px !important;
}
.sidebar-expanded > ul > a > .align-items-center {
  margin-top: 5px;
}

.sidebar-expanded > ul > a > .align-items-center > .icon-container {
  font-size: 1.33em;
}

.sidebar-collapsed {
  width: 80px !important;
}

.sidebar-collapsed > ul > a > .d-flex {
  height: 80px !important;
}

.sidebar-collapsed > ul > a > .align-items-center > .icon-container {
  font-size: 2.8em;
}
*/
</style>
-->

const helper = {
  toName(string) {
    if (string) {
      return string.charAt(0).toUpperCase() + string.slice(1)
    } else {
      return ''
    }
  },
  shortString(str, max) {
    if (!str || typeof str !== 'string' || str.length === 0) {
      return str
    }
    if (str.length <= max) {
      return str
    }
    return str.substring(0, max) + '...'
  },
  matches(obj1, obj2) {
    let matches = true
    for (let key of Object.keys(obj1)) {
      if (!(key in obj2) || obj1[key] !== obj2[key]) {
        matches = false
        break
      }
    }
    return matches
  },
  fileSrc(id) {
    return '/api/download/' + id
  },
  imgSrc(id, thumbnail) {
    let url = '/api/download/' + id
    if (thumbnail) {
      url += '?thumbnail=true'
    }
    return url
  },
  download(filename, data, header) {
    const blob = new Blob([data], { type: header })
    const link = document.createElement('a')
    link.href = URL.createObjectURL(blob)
    link.download = filename
    link.click()
    URL.revokeObjectURL(link.href)
    link.remove()
  },
  async validateYup(schema, values, errors) {
    try {
      await schema.validate(values, { abortEarly: false })
      for (let value of Object.keys(errors)) {
        errors[value] = ''
      }
      return true
    } catch (err) {
      err.inner.forEach((error) => {
        errors[error.path] = error.message
      })
      return false
    }
  },

  wait: (ms) => new new Promise((resolve) => setTimeout(resolve, ms))(),
}

export default helper

import { createRouter, createWebHistory } from 'vue-router'

import store from './store'
import Cookies from 'js-cookie'

import Admin from './views/Admin.vue'
import Login from './views/Login.vue'
import Users from './views/Users.vue'
import Office from './views/Office.vue'
import Komsol from './views/Komsol.vue'
import Articles from './views/Articles.vue'
import Menus from './views/Menus.vue'
import FilePage from './views/FilePage.vue'

import ArticleForm from './components/forms/ArticleForm.vue'
import MenuForm from './components/forms/MenuForm.vue'
import UserForm from './components/forms/UserForm.vue'

const routes = [
  {
    path: '/',
    name: 'index',
    component: Admin,
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: 'users',
        name: 'users',
        component: Users,
      },
      {
        path: 'users/edit/:id',
        name: 'edit-user',
        component: UserForm,
      },
      {
        path: 'users/create',
        name: 'create-user',
        component: UserForm,
      },
      {
        path: 'office',
        name: 'office',
        component: Office,
      },
      {
        path: 'filebrowser',
        name: 'filebrowser',
        component: FilePage,
      },
      {
        path: 'komsol',
        name: 'komsol',
        component: Komsol,
      },
      {
        path: '/komsol/menus/',
        name: 'menus',
        component: Menus,
      },
      {
        path: '/komsol/menus/edit/:id?',
        name: 'edit-menu',
        component: MenuForm,
      },
      {
        path: '/komsol/menus/create/',
        name: 'create-menu',
        component: MenuForm,
      },
      {
        path: '/komsol/articles/',
        name: 'articles',
        component: Articles,
      },
      {
        path: '/komsol/articles/edit/:id?',
        name: 'edit-article',
        component: ArticleForm,
      },
      {
        path: '/komsol/articles/create/:id?',
        name: 'create-article',
        component: ArticleForm,
      },
    ],
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

router.afterEach((to, from) => {
  // Complete the animation of the route progress bar.
  store.state.loadingPage = false
})

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    const jwtCookie = Cookies.get('jwt')
    store.commit('setJwt', jwtCookie)

    if (!store.getters.hasJwt) {
      next({
        name: 'login',
        params: { nextUrl: to.fullPath },
      })
    } else {
      next()
    }
  } else {
    next()
  }
})

export default router

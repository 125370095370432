<template>
  <div :class="divClass">
    <label v-if="label?.length > 0" :for="name" class="form-label">
      {{ label }}
    </label>

    <select
      :id="name"
      :name="name"
      :class="['form-select', { 'is-invalid': error }]"
      v-model="value"
      @blur="$emit('validate')"
    >
      <option value="" selected disabled>Select: {{ this.placeholder }}</option>
      <option v-for="option in data" :key="option.value" :value="option.value">
        {{ option.text }}
      </option>
    </select>
    <div class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'wSelect',
  emits: ['update:modelValue', 'change', 'validate'],
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    data: {
      type: Array,
      default: new Array(),
    },
    error: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: 'Select any option',
    },
    label: {
      type: String,
      default: '',
    },
    divClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('change', value)
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>

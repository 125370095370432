<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #start>
        <Button
          label="Create Menu"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          @click="createItem()"
        />
      </template>
    </Toolbar>

    <DataTable
      dataKey="id"
      ref="dt"
      filterDisplay="menu"
      :value="items"
      :paginator="true"
      :rowHover="true"
      :rows="10"
      :totalRecords="totalRows"
      :lazy="true"
      v-model:filters="filters"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
          <h5 class="p-m-0">Menu</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="searchString" placeholder="Search" />
          </span>
        </div>
      </template>
      <template #empty> No menus found. </template>
      <template #loading> Loading menus data. Please wait. </template>

      <Column field="text" header="Text" ref="text" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Text"
          />
        </template>
      </Column>
      <Column field="langCode" header="Language" ref="langCode" sortable>
        <template #body="{ data }">
          <span :class="'flag-icon flag-icon-' + data.langCode"></span>
        </template>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Language"
          />
        </template>
      </Column>
      <Column field="group" header="Group" ref="group" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Group"
          />
        </template>
      </Column>
      <Column field="order" header="Order" ref="order" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="number"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Order"
          />
        </template>
      </Column>

      <Column :exportable="false" style="min-width: 8rem">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            style="margin-right: 10px"
            @click="editItem(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            @click="deleteItem(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
export default {
  name: 'Menus',
  components: {},
  data() {
    return {
      searchTimer: undefined,
      loading: true,
      searchString: null,
      items: [],
      totalRows: 0,
      filters: {
        text: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        langCode: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        group: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        order: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
          type: 'int',
        },
      },
      params: {},
    }
  },
  async mounted() {
    await this.getItems()
  },
  methods: {
    getParamQuery() {
      return {
        rows: this.params.rows,
        first: this.params.first,
        sortOrder: this.params.sortOrder,
        sortField: this.params.sortField,
        filters: this.filters,
        search: this.searchString,
      }
    },
    async getItems() {
      this.loading = true
      const res = await this.$axios.get('komsol/menus', {
        params: this.getParamQuery(),
      })
      this.items = res.data.items
      this.totalRows = res.data.total

      this.loading = false
    },
    async onPage(event) {
      this.params = event
      await this.getItems()
    },
    async onSort(event) {
      this.params = event
      await this.getItems()
    },
    async onFilter() {
      this.params.filters = this.filters
      await this.getItems()
    },
    createItem() {
      this.$router.push({ name: 'create-menu' })
    },
    editItem(item) {
      this.$router.push({ name: 'edit-menu', params: { id: item.id } })
    },
    deleteItem(item) {},
  },
  watch: {
    searchString() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(async () => {
        await this.getItems()
      }, 200)
    },
  },
}
</script>

<template>
  <div :id="modalId" :ref="modalId" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <form>
          <div class="modal-header">
            <h4 class="modal-title">Alert</h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>{{ this.text }}</p>
          </div>
          <div class="modal-footer">
            <input
              type="button"
              class="btn btn-danger"
              value="Ignore changes"
              @click="handleIgnore"
            />
            <input
              type="button"
              class="btn btn-primary"
              value="Go back"
              @click="handleCancel"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  name: 'ModalAlert',
  props: {
    modalId: String,
    text: String,
  },
  emits: ['close'],
  data() {
    return {
      modal: null,
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs[this.modalId])
  },
  methods: {
    show() {
      this.modal.show()
    },
    handleIgnore() {
      this.modal.hide()
      this.$emit('close', true)
    },
    handleCancel() {
      this.modal.hide()
      this.$emit('close', false)
    },
  },
}
</script>

<template>
  <div>
    <the-topbar />
    <div class="container-fluid">
      <div class="row">
        <the-sidebar />

        <main class="col-md-9 ms-sm-auto col-lg-10 px-md-4 py-md-5">
          <the-navigation />

          <div class="container" style="padding: 0px; margin: 0px">
            <template v-if="$store.state.page === 'index'">
              <dashboard />
            </template>
            <template v-else>
              <router-view />
            </template>
          </div>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import TheTopbar from '@/components/static/TheTopbar'
import TheSidebar from '@/components/static/TheSidebar'
import TheNavigation from '@/components/static/TheNavigation'

import Dashboard from './Dashboard'

export default {
  name: 'Main',
  components: {
    TheTopbar,
    TheSidebar,
    TheNavigation,
    Dashboard,
  },
  async mounted() {
    await this.getAllStoreTypes()
  },
  methods: {
    async getAllStoreTypes() {
      await this.$store.dispatch('getAllOffices')
      await this.$store.dispatch('getAllRoles')
      await this.$store.dispatch('getKomsolTypes')
    },
  },
}
</script>

<style>
@import '../assets/styles/_dashboard.scss';
@import '../assets/styles/_topbar.scss';
@import '../assets/styles/_sidebar.scss';
@import '../assets/styles/_modal.scss';
</style>

<template>
  <div>
    <editor
      id="tinymceId"
      v-model="text"
      :apiKey="apiKey"
      :init="config"
      :onInit="onTinyInit"
    />
    <modal-browser modalId="modalFileBrowserEditor" ref="modalBrowser" />
  </div>
</template>

<script>
import ModalBrowser from '@/components/modals/ModalBrowser.vue'

import Editor from '@tinymce/tinymce-vue'
//import { komsolStyle } from '@/components/komsol/komsolArticleStyle.js'
import { komsolStyle } from '@/utils/komsolArticleStyle.js'

const getLinkElement = (element) => {
  if (element.nodeName === 'A') {
    return element
  }

  return element.parentElement
}

export default {
  name: 'AppArticleEditor',
  components: { Editor, ModalBrowser },
  props: {
    modelValue: String,
  },
  emits: ['update:modelValue'],
  methods: {
    urlCallback(url, node, on_save) {
      const imgSuffix = ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
      const split = url.split('/')
      const filename = split[split.length - 1]
      const xmlHttp = new XMLHttpRequest()

      let fileUrl = ''
      if (imgSuffix.some((x) => url.toLowerCase().endsWith(x))) {
        fileUrl = '/api/filebrowser?image=true&filename=' + filename
      } else if (url.toLowerCase().endsWith('.pdf')) {
        fileUrl = 'api/filebrowser?filename=' + filename
      } else {
        return url
      }
      if (fileUrl.length === 0) {
        return url
      }
      xmlHttp.open('GET', fileUrl, false)
      xmlHttp.send()
      const json = JSON.parse(xmlHttp.responseText)
      console.log('Download', filename, json)

      if (json.length > 0) {
        return this.$helper.imgSrc(json[0].id, false)
      }
      return url
    },

    getImageList() {
      this.$axios
        .get('/filebrowser?image=true')
        .then((res) => {
          this.tinymce.settings.image_list = res.data.map((x) => {
            return { title: x.filename, value: 'filebrowser/download/' + x.id }
          })
        })
        .catch((err) => {
          console.log('failed to get image list')
        })
    },
    onTinySetup(editor) {
      editor.ui.registry.addButton('imageBrowser', {
        text: '',
        icon: 'image',
        onAction: () => {
          const node = editor.selection.getNode()
          this.$refs.modalBrowser.node = node
          this.$refs.modalBrowser
            .show('images')
            .then((res) => {
              const img = this.$helper.imgSrc(res.id, false)
              if (node && node.nodeName.toLowerCase() === 'img') {
                editor.selection.setNode(
                  editor.dom.create('img', {
                    src: img,
                    width: res.width,
                    height: res.height,
                  })
                )
                this.text = editor.getContent()
              } else {
                const imgHtml = `<img src="${img}" width="${res.width}" height="${res.height}"/>`
                editor.insertContent(imgHtml)
              }
            })
            .catch(() => {})
        },
      })

      editor.ui.registry.addButton('fileBrowser', {
        text: '',
        icon: 'document-properties',
        onAction: () => {
          const node = getLinkElement(editor.selection.getNode())
          this.$refs.modalBrowser.node = node
          this.$refs.modalBrowser
            .show('files')
            .then((res) => {
              const file = this.$helper.fileSrc(res.id)
              if (res.icon && res.icon.length > 0) {
                const icon = this.$helper.imgSrc(res.icon, false)
                const fileHtml = `<a href="${file}" target="_blank"><img class="documimg" alt="TDS.png" src="${icon}"></a>`
                editor.insertContent(fileHtml)
              } else {
                const fileHtml = `<a href="${file}" target="_blank">${res.filename}</a>`
                editor.insertContent(fileHtml)
              }
            })
            .catch(() => {})
        },
      })
    },
    onTinyInit(event, editor) {
      this.tinymce = editor
      /*
      this.getImageList()
      editor.settings.images_upload_handler = (blobInfo, success, failure) => {
        const formData = new FormData()
        formData.append('file', blobInfo.blob(), blobInfo.filename())
        this.$axios
          .post('/images?folder=komsol/articles', formData)
          .then((res) => {
            success('filebrowser/download/' + res.data.id)
            this.getImageList()
          })
          .catch((err) => {
            failure('Error: ' + err.message)
          })
      }
      */
    },
  },
  data() {
    return {
      text: '',
      apiKey: process.env.VUE_APP_TINY_MCE_KEY,
      tinymce: undefined,
      imageIds: [],

      config: {
        height: 900,
        menubar: true,
        convert_urls: false,
        //relative_urls: true,
        //remove_script_host: false,
        //document_base_url: process.env.VUE_APP_MY_URL,
        //urlconverter_callback: this.urlCallback,
        force_br_newlines: false,
        force_p_newlines: false,
        forced_root_block: '',

        images_upload_credentials: true,
        toolbar_mode: 'wrap',
        content_css:
          'https://cdn.jsdelivr.net/npm/bootstrap@3.3.7/dist/css/bootstrap.min.css',
        content_style: komsolStyle,
        plugins: [
          'advlist autolink lists link image charmap',
          'searchreplace visualblocks code print',
          'preview anchor insertdatetime media spellchecker',
          'paste code help wordcount table fullscreen contextmenu',
        ],
        plugin_preview_width: '500',
        toolbar:
          'undo redo | fontselect | bold italic underline | forecolor backcolor | alignleft aligncenter alignright |\
               formatselect | link | imageBrowser fileBrowser | bullist numlist outdent indent | code preview fullscreen',
        setup: this.onTinySetup,
      },
    }
  },
  watch: {
    modelValue() {
      this.text = this.modelValue
    },
    text() {
      this.$emit('update:modelValue', this.text)
    },
  },
}

/*
    async fixUrls() {
      const dom = this.tinymce.getBody()
      let elements = dom.getElementsByTagName('img')
      const accepted = ['.png', '.jpg', '.jpeg', '.gif', '.bmp']
      for (const e of elements) {
        const src = e.currentSrc
        if (!accepted.some((x) => src.endsWith(x))) {
          console.log('ignore ', src)
          continue
        }
        const split = src.split('/')
        const filename = split[split.length - 1]
        const res = await this.$axios.get('/filebrowser?image=true', {
          params: { filename: filename },
        })
        console.log(filename, res.data)
        console.log(e)
        if (res.data.length > 0) {
          //e.setAttribute('src', 'filebrowser/download/' + res.data[0].id)
          e.src = 'filebrowser/download/' + res.data[0].id
        }
      }
      var iframe = document.getElementById('tinymceId_ifr')
      var body = iframe.contentWindow.document.body
      console.log(body)
      console.log(body.innerHTML)

      //console.log(body.innerHTML)
      //this.tinymce.setContent(body.innerHTML)
    },
*/
</script>

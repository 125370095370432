<template>
  <div v-if="hasValues">
    <form>
      <div
        v-if="showToolbar"
        class="
          d-flex
          flex-wrap flex-md-nowrap
          align-items-center
          justify-content-between
        "
        style="margin-bottom: 20px"
      >
        <h3 class="title-5">{{ titleString }}</h3>
        <div class="btn-toolbar mb-2 mb-md-0">
          <div class="btn-group me-2">
            <button
              type="submit"
              @click.prevent="submit"
              class="btn btn-outline-primary"
            >
              Submit Changes
            </button>
            <button
              @click="onCancel"
              type="button"
              class="btn btn-outline-danger"
            >
              Cancel
            </button>
          </div>
        </div>
      </div>
      <slot :values="values" :errors="errors" :validate="validateField"> </slot>
    </form>
    <app-modal-alert
      modalId="cancelModal"
      text="There are unsaved changes"
      ref="cancelModal"
      @close="cancelModalCallback"
    />
  </div>
</template>

<script>
import AppModalAlert from '../modals/ModalAlert.vue'

import { yupValidateForm, yupValidateField } from '@/utils/yup'

export default {
  name: 'AppForm',
  components: { AppModalAlert },
  props: {
    title: {
      type: String,
      default: 'Form',
    },
    url: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      default: null,
    },
    schema: {
      type: Object,
      default: null,
    },
    showToolbar: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      values: {},
      errors: {},
      editItem: null,
    }
  },
  mounted() {},
  computed: {
    currentId() {
      return this.id ? this.id : this.$route.params.id
    },
    requestType() {
      return this.currentId ? 'PUT' : 'POST'
    },
    editUrl() {
      return this.url + '/' + this.currentId
    },
    hasValues() {
      return this.values && Object.keys(this.values).length > 0
    },
    titleString() {
      const prefix = this.currentId ? 'Edit' : 'Create'
      return prefix + ' ' + this.title
    },
  },

  methods: {
    async submit() {
      //Make sure atleast one field has been changed when PUT
      if (this.requestType === 'PUT') {
        if (this.$helper.matches(this.values, this.editItem)) {
          alert('No fields has been changed')
          return
        }
      }

      //Validate all fields with yup
      const validForm = await this.validateForm()
      if (!validForm) {
        return
      }

      //Submit request
      if (this.requestType === 'POST') {
        console.log('post')
        await this.$axios.post(this.url, this.values)
      } else if (this.requestType === 'PUT') {
        console.log('put')
        await this.$axios.put(this.editUrl, this.values)
      }

      //Reset form
      this.resetValues()
      this.$router.go(-1)
    },
    async getEditItem() {
      /*
      if (this.id) {
        await this.$store.dispatch('getItem', this.editUrl)
        this.editItem = this.$store.getters.getItem
      } else {
        const res = await this.$axios.get(this.editUrl)
        this.editItem = res.data
      }*/
      await this.$store.dispatch('getItem', this.editUrl)
      this.editItem = this.$store.getters.getItem

      this.values = Object.assign({}, this.editItem)
    },
    resetValues() {
      const data = []
      for (const field in this.schema.fields) {
        //const emptyValue = field.type === 'int' ? 0 : ''
        data[field.name] = null
      }
      this.values = Object.assign({}, data)
      this.editItem = null
    },
    onCancel() {
      if (!this.$helper.matches(this.values, this.editItem)) {
        this.$refs.cancelModal.show()
      } else {
        this.$router.go(-1)
      }
    },
    cancelModalCallback(value) {
      if (value) {
        this.$router.go(-1)
      }
    },
    async validateForm() {
      if (!this.schema) {
        return true
      }
      try {
        await yupValidateForm(this.schema, this.values, this.errors)
        return true
      } catch {
        return false
      }
    },
    validateField(field) {
      if (this.schema) {
        yupValidateField(field, this.schema, this.values, this.errors)
      }
    },
  },
  watch: {
    currentId: {
      async handler(value) {
        this.resetValues()
        if (value) {
          await this.getEditItem()
        }
      },
      immediate: true,
    },
  },
}
</script>

<template>
  <div class="row">
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb">
        <li
          v-for="(item, index) in titleParams"
          :key="index"
          :class="[
            'breadcrumb-item',
            { active: titleParams.length - 1 === index },
          ]"
        >
          <template v-if="titleParams.length - 1 !== index">
            <router-link :to="{ name: item }" title>
              {{ this.$helper.toName(item) }}
            </router-link>
          </template>
          <template v-else>
            {{ this.$helper.toName(item) }}
          </template>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'TheNavigation',
  computed: {
    titleParams() {
      const params = this.$route.path.split('/').filter((x) => x !== '')
      return this.$route.matched.map((x) => x.name)
    },
  },
}
</script>

<template>
  <header
    class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow"
  >
    <button
      class="navbar-toggler d-md-none collapsed"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#sidebarMenu"
      aria-controls="sidebarMenu"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <router-link
      :to="{ name: 'index' }"
      class="navbar-brand col-md-3 col-lg-2 me-0 px-3"
    >
      Dataseal Admin
    </router-link>
    <div class="navbar-nav ms-auto">
      <div class="nav-item text-nowrap">
        <button class="btn btn-link nav-link px-3" @click.prevent="logout">
          Sign out
        </button>
      </div>
    </div>
  </header>
</template>

<script>
export default {
  name: 'TheTopbar',
  components: {},
  data() {
    return {
      searchBar: '',
    }
  },
  methods: {
    logout() {
      this.$store.dispatch('logout')
      this.$router.push('/login')
    },
  },
  watch: {
    searchBar(value) {
      this.$store.state.searchString = value
    },
    $route(to, from) {
      this.searchBar = ''
      this.$store.state.searchString = ''
    },
  },
}
</script>

<template>
  <AppFileBrowser />
</template>

<script>
import AppFileBrowser from '@/components/app/AppFileBrowser'
export default {
  name: 'FilePage',
  components: { AppFileBrowser },
}
</script>

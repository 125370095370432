import axios from 'axios'

export default class OfficeService {
  constructor() {
    this.url = 'offices/'
  }
  async get() {
    const res = await axios.get(this.url)
    return res.data
  }
  async getNames() {
    const res = await axios.get(this.url + 'names')
    return res.data
  }
  async post(formData) {
    const res = await axios.post(this.url, formData)
    return res.data
  }
  async put(id, formData) {
    const res = await axios.put(this.url + id, formData)
    return res.data
  }
  async delete(id, formData) {
    const res = await axios.delete(this.url + id, formData)
    return res.data
  }
}

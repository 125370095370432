<template>
  <div><span :class="spanClass">&#9679;</span> {{ text }}</div>
</template>

<script>
export default {
  name: 'wStatus',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('update:modelValue', value)
      },
    },
    spanClass() {
      return this.modelValue ? 'text-success' : 'text-danger'
    },
    text() {
      return this.modelValue ? 'Active' : 'Inactive'
    },
  },
}
</script>

export const komsolStyle = `
div {
  border: solid 0px green;
}
/*CLASSER I BOOTSTRAP SOM ÄR ÄNDRADE*/
@media (max-width: 808px) {
  .row {
    margin-right: -10px !important;
  }
}

body {
  color: black !important;
  /*background-color: #565656;*/
  font-family: Arial, Helvetica, sans-serif !important;
  font-size: 13px !important;
  line-height: 1.42857 !important;
  background-color: #fff !important;
  margin-top: 10px;
  width: 960px !important;
  padding: 50px;
}

/*KOLUMNER DIVS*/
.div_text {
  padding-bottom: 25px;
}

/*ÖVRIGA*/
hr {
  color: lightgray !important;
  height: 1px !important;
  border-color: lightgrey !important;
  border-style: solid !important;
  margin-top: 15px;
  margin-bottom: 0px;
}

img {
  display: block !important;
  height: auto !important;
  max-width: 100% !important;
}

/*TEXT CLASSER #################################################################################################################*/
/*
  H1         		ARIAL       REGULAR         30pt    (Sidrubriker)
  H2         		ARIAL       REGULAR         18pt    (
  H3      		ARIAL       BOLD            13pt
  H4		        ARIAL	    BOLD	        18pt    #FFFFFF  (vit) sektions rubriker
  H5		        ARIAL	    BOLD	        14pt    #FFFFFF  (vit) produkt rubriker och knappar.
  H6		        ARIAL	    BOLD	        24pt    #
  INTRO         	ARIAL       REGULAR         16pt    (ingresser)
  NORMAL 	        ARIAL       REGULAR         13pt    (brödtext)
  LIST LARGE	    ARIAL	    REGULAR         16pt    (punkt, vit #FFFFFF)  framsida punktlista products/etc
  LIST SMALL	    ARIAL	    REGULAR         12pt    indrag 3 pt 
  IMAGE TEXT 	    ARIAL 	    REGULAR         13pt    #999999 (grå) Bildtexter
  TOP MENU	    ARIAL   	REGULAR         15pt    (topmenyer i headern)
  FOOT MENU	    ARIAL	    REGULAR         13pt    (rubriker på menyerna i sidfoten)
  FOOT LINK 	    ARIAL       REGULAR         11pt    (länkar i sidfoten)
  FORM H	        ARIAL	    BOLD	        14pt    #0477C1    (fältrubriker formulär)
  */
/*HEADER PÅ INDEXSIDAN*/
.indexHeader1 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 45px;
  color: #bebfbf;
}

.indexHeader2 {
  color: #55a6d6;
}

.indexHeader3 {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 35px;
  color: #bebfbf;
}
/*h1 för rubriker överst på varje sida*/
h1 {
  font-size: 30px !important;
  padding-bottom: 20px;
  margin-top: 0px !important;
}
/*h2 för underrubriker på sidor*/
h2 {
  font-size: 18px !important;
  font-style: normal;
  margin: 0px;
}

h3 {
  font-size: 13px !important;
  font-weight: bold;
  font-style: normal;
  margin: 0px;
}

h4 {
  font-size: 18px !important;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
  margin: 0px;
}

h5 {
  font-size: 14px !important;
  font-weight: bold;
  font-style: normal;
  color: #ffffff;
}
h6 {
  font-size: 24px !important;
  font-weight: bold;
  font-style: normal;
}
/*NYHETER STARTSIDAN*/
.newsreadmoretext {
  color: black;
}
.newsdate {
  text-align: right;
  margin-right: 20px;
}
.newsingress {
  /*font-size:13px !important;*/
  font-weight: normal;
  font-style: normal;
  margin: 0px;
}

@media (min-width: 960px) {
  .newspager {
    float: left;
    width: 100%;
    margin-left: 40px;
    padding-right: 80px;
  }
}
@media (max-width: 959px) {
  .newspager {
    float: left;
    width: 100%;
    margin-left: 0px;
    padding-right: 0px;
  }
}

.newscard {
  border: 1px solid #bebfbf;
  margin-left: 45px;
  margin-bottom: 10px;
  margin-top: 30px;
  padding: 10px;
  width: 250px;
  height: 300px;
  float: left;
  position: relative;
}
/*KONTAKTSIDAN*/
.ContacsHeader {
  text-decoration: none !important;
  font-weight: normal !important;
  color: black;
  margin-left: 10px;
}
.TDcontacts {
  height: 25px;
}
.contacts {
  border: 0px solid black;
  background-color: #fff;
  display: none;
  margin-left: 50px;
}
/*###########################
  BLUEIMP GALLERY
  ###########################*/
.title {
  left: 20% !important;
}
@media (min-width: 960px) {
  .blueimp-gallery {
    border: 10px solid black;
    width: 980px;
    margin-left: auto;
    margin-right: auto;
  }
}
/*###########################*/
/*IMAGE TEXT 	    ARIAL 	    REGULAR         13pt    #999999 (grå) Bildtexter*/
.imagetext {
  font-size: 13px !important;
  font-weight: normal;
  color: #999999;
  margin: 0px;
}
/*FOOT LINK 	    ARIAL       REGULAR         11pt    (länkar i sidfoten)*/
.sitemap {
  font-size: 11px;
}
/*LIST SMALL	    ARIAL	    REGULAR         12pt    indrag 3 pt*/
.listsmall {
  font-size: 12px !important;
  font-weight: normal;
}
/*LIST LARGE	    ARIAL	    REGULAR         16pt    (punkt, vit #FFFFFF)  framsida punktlista products/etc*/
.listlarge {
  font-size: 16px !important;
  font-weight: normal;
  color: #fff;
}
/*INTRO         	ARIAL       REGULAR         16pt    (ingresser)*/
.intro {
  font-size: 16px !important;
  font-weight: normal;
}
/*DOKUMENTATION*/
.documtable {
  background-color: white;
}
.documheadrow {
  background-color: #55a6d6;
}
.documtable td {
  padding: 7px;
}
.documtdicon {
  text-align: center !important;
  width: 60px;
}
.documimg {
  border-width: 0px;
  display: inline !important;
}
/*########################################################################*/
/*NEWS*/
.newsflashimage {
  max-height: 100px;
}
/*########################################################################*/
/*FOOTER*/
@media only screen and (min-width: 768px) {
  .footer_main {
    width: 940px;
    margin: auto;
  }
  .footer_content_left {
    display: inline-block;
    width: 308px;
    text-align: left;
    padding-bottom: 40px;
    padding-left: 15px;
  }
  .footer_content_middle {
    display: inline-block;
    width: 308px;
    text-align: center;
    padding-bottom: 40px;
  }
  .footer_content_right {
    display: inline-block;
    width: 308px;
    text-align: right;
    padding-bottom: 40px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 940px) {
  .footer_main {
    width: 100%;
    margin: auto;
  }
  .footer_content_left {
    display: inline-block;
    width: 310px;
    text-align: center;
    padding-bottom: 40px;
    padding-top: 20px;
  }
  .footer_content_middle {
    display: inline-block;
    width: 310px;
    text-align: center;
    padding-bottom: 40px;
  }
  .footer_content_right {
    display: inline-block;
    width: 310px;
    text-align: center;
    padding-bottom: 40px;
  }
}

`

import '@popperjs/core'
import 'es6-promise/auto'

//import 'bootstrap/dist/css/bootstrap.min.css'
//import './assets/styles/theme.scss'
//import './assets/themes/cosmo.scss'

import 'bootswatch/dist/cosmo/bootstrap.min.css'
import 'flag-icon-css/css/flag-icon.min.css'

import 'primevue/resources/themes/saga-blue/theme.css'
import 'primevue/resources/primevue.min.css'
import 'primeicons/primeicons.css'

import './assets/styles/primevue.scss'
import './assets/styles/index.scss'

import 'bootstrap'

import { createApp } from 'vue'
import App from './App.vue'

import store from './store'
import router from './router'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import helper from './utils/helper'
import { registerIcons } from './utils/registerIcons'
import { registerPrimeVue } from './utils/registerComponents'

import axios from 'axios'

document.title = 'Komsol Admin'

axios.defaults.baseURL = process.env.VUE_APP_MY_URL
axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json'
axios.defaults.headers.put['Content-Type'] = 'application/json'
axios.defaults.headers.get['Access-Control-Allow-Origin'] = '*'

const app = createApp(App)
app.config.globalProperties.$helper = helper
app.config.globalProperties.$axios = axios

store.$helper = helper
store.$axios = axios

registerIcons()

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router)
app.use(store)

registerPrimeVue(app)

axios.interceptors.response.use(undefined, function (error) {
  if (error.response.status === 401) {
    router.push({ name: 'login' })
  }
  return Promise.reject(error)
})

app.mount('#app')

import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faPlus,
  faMinus,
  faEdit,
  faTrash,
  faThLarge,
  faGlobe,
  faUsers,
  faStickyNote,
  faBars,
  faCog,
  faPlusCircle,
  faMinusCircle,
  faFile,
  faFilePdf,
  faFolder,
  faFolderOpen,
  faFolderPlus,
  faSitemap,
  faSort,
  faSortDown,
  faSortUp,
  faCheckSquare,
  faImages,
  faCaretSquareLeft,
  faBuilding,
} from '@fortawesome/free-solid-svg-icons'

export const registerIcons = () => {
  library.add(
    faPlus,
    faMinus,
    faEdit,
    faTrash,
    faThLarge,
    faGlobe,
    faUsers,
    faStickyNote,
    faBars,
    faCog,
    faMinusCircle,
    faPlusCircle,
    faFile,
    faFilePdf,
    faFolder,
    faFolderOpen,
    faFolderPlus,
    faSitemap,
    faSort,
    faSortDown,
    faSortUp,
    faCheckSquare,
    faImages,
    faCaretSquareLeft,
    faBuilding
  )
}

<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #start>
        <Button
          label="Create Office"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          @click="createItem()"
        />
      </template>
    </Toolbar>

    <DataTable
      dataKey="id"
      ref="dt"
      filterDisplay="menu"
      responsiveLayout="scroll"
      :value="items"
      :rowHover="true"
      :lazy="true"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #empty> No offices found. </template>
      <template #loading> Loading office data. Please wait. </template>

      <Column field="name" header="Name" ref="name"> </Column>
      <Column field="userCount" header="User Count" ref="userCount"></Column>
      <Column :exportable="false" style="min-width: 8rem">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            style="margin-right: 10px"
            @click="editItem(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            @click="deleteItem(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import Swal from 'sweetalert2'
import OfficeService from '@/services/office'

export default {
  name: 'Office',
  components: {},

  data() {
    return {
      searchTimer: undefined,
      loading: true,
      items: [],
    }
  },
  async mounted() {
    this.service = new OfficeService()
    await this.getItems()
  },
  methods: {
    async getItems() {
      this.loading = true
      const data = await this.service.get()
      this.items = data.items
      this.loading = false
    },
    async onPage(event) {
      this.params = event
      await this.getItems()
    },
    async onSort(event) {
      this.params = event
      await this.getItems()
    },
    async onFilter() {
      this.params.filters = this.filters
      await this.getItems()
    },
    async createItem() {
      const { value: text } = await Swal.fire({
        title: 'Create name',
        input: 'text',
        inputPlaceholder: 'Enter office name',
      })

      if (text) {
        await this.service.post({ name: text })
        await this.getItems()
      }
    },
    async editItem(item) {
      const { value: text } = await Swal.fire({
        title: 'Edit office name',
        input: 'text',
        inputValue: item.name,
      })

      if (text) {
        await this.service.put(item.id, { name: text })
        await this.getItems()
      }
    },
    async deleteItem(item) {
      if (item.userCount > 0) {
        const text = `Office ${item.name} has ${item.userCount} users`
        await Swal.fire('Cannot delete office', text, 'warning')
        return
      }
      const { isConfirmed } = await Swal.fire({
        title: 'Do you want to delete office: ' + item.name + '?',
        showCancelButton: true,
        confirmButtonText: 'Yes!',
        confirmButtonColor: 'red',
      })

      if (isConfirmed) {
        await this.service.delete(item.id)
        await this.getItems()
      }
    },
  },
  computed: {},
}
</script>

<template>
  <div>
    <app-form
      :url="url"
      :schema="schema"
      title="Menu"
      ref="form"
      v-slot="{ values, errors, validate }"
    >
      <div class="row g-3">
        <div class="col-sm-6">
          <w-form-input
            name="text"
            label="Text"
            type="text"
            v-model="values.text"
            :error="errors.text"
            @validate="validate('text')"
          />
        </div>
        <div class="col-sm-6">
          <w-form-input
            name="group"
            label="Group"
            type="text"
            v-model="values.group"
            :error="errors.group"
            @validate="validate('group')"
          />
        </div>
        <div class="col-sm-6">
          <w-select
            name="inputLangauge"
            placeholder="Language"
            label="Language"
            :data="languages"
            v-model="values.langCode"
            :error="errors.langCode"
            @validate="validate('langCode')"
          />
        </div>
        <div class="col-sm-6">
          <w-form-input
            name="order"
            label="Order"
            type="number"
            v-model.number="values.order"
            :error="errors.order"
            @validate="validate('order')"
          />
        </div>
      </div>
    </app-form>
    <sub-menu-form />
  </div>
</template>

<script>
import AppForm from '@/components/app/AppForm.vue'
import SubMenuForm from '@/components/forms/SubMenuForm.vue'

import { MenuSchema } from '@/utils/yup'

export default {
  name: 'MenuForm',
  components: { AppForm, SubMenuForm },
  setup() {
    return {
      schema: MenuSchema(),
    }
  },
  computed: {
    currentId() {
      return this.$route.params.id
    },
    url() {
      return 'komsol/menus/'
    },
    languages() {
      return this.$store.getters.komsol_langs
    },
  },
}
</script>

import { object, string, number } from 'yup'

const yup = {
  object,
  string,
  number,
}

export default yup

const yup_fields = {
  group: () => yup.string().required().min(3).max(1000),
  order: () => yup.number().required().min(0).max(10000),
  langCode: () => yup.string().required(),
}

export const PasswordField = () => {
  return string().required().min(6)
}
export const UserSchema = () => {
  return yup.object({
    firstname: yup.string().required(),
    lastname: yup.string().required(),
    email: yup.string().email().required(),
    role: yup.string().required(),
    office: yup.string().required(),
    phoneNumber: yup.string().optional(),
    password: yup
      .string()
      .notRequired()
      .min(8)
      .nullable()
      .transform((value) => (value ? value : null)),
  })
}

export const LoginSchema = () => {
  return yup.object({
    email: yup.string().email().required(),
    password: yup.string().required().min(6),
  })
}

export const MenuSchema = () => {
  return yup.object({
    text: yup.string().required().min(2).max(30),
    group: yup_fields.group(),
    order: yup_fields.order(),
    langCode: yup_fields.langCode(),
  })
}

export const SubMenuSchema = () => {
  return yup.object({
    text: yup.string().required().min(2).max(30),
    group: yup_fields.group(),
    order: yup_fields.order(),
  })
}

export const ArticleSchema = () => {
  return yup.object({
    title: yup.string().required().min(3),
    group: yup_fields.group(),
    langCode: yup_fields.langCode(),
    ingress: yup.string().optional().nullable(true).max(500),
    metaWords: yup.string().optional().nullable(true).max(500),
    metaDescr: yup.string().optional().nullable(true).max(500),
    imageFolder: yup.string().optional().nullable(true).max(500),
  })
}
export const yupValidateForm = async (schema, values, errors) => {
  try {
    await schema.validate(values, { abortEarly: false })
    for (let value of Object.keys(errors)) {
      errors[value] = ''
    }
  } catch (err) {
    err.inner.forEach((error) => {
      errors[error.path] = error.message
    })
    throw err
  }
}

export const yupValidateField = (field, schema, values, errors) => {
  schema
    .validateAt(field, values)
    .then(() => {
      errors[field] = ''
    })
    .catch((err) => {
      errors[field] = err.message
    })
}

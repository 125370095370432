<template>
  <div>
    <div v-if="isLoading" id="fullscreen-spinner">
      <div class="d-flex justify-content-center">
        <div
          class="spinner-grow text-primary"
          role="status"
          style="width: 4rem; height: 4rem; z-index: 20"
        >
          <span class="sr-only">Loading...</span>
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
export default {
  name: 'App',
  components: {},
  mounted() {
    //this.$store.state.page = this.$route.name
  },
  computed: {
    isLoading() {
      return this.$store.state.loadingPage
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        if (to.name) {
          this.$store.state.page = to.name
        }
      },
      immediate: true,
    },
  },
}
</script>

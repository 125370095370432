<template>
  <div :class="divClass">
    <label :for="name" class="form-label">{{ label }}</label>
    <input
      :id="name"
      :name="name"
      :type="type"
      :class="['form-control', { 'is-invalid': error }]"
      :placeholder="placeholder"
      v-model="value"
      @blur="$emit('validate')"
    />
    <div class="invalid-feedback">
      {{ error }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'wFormInput',
  emits: ['update:modelValue', 'change', 'validate'],
  props: {
    modelValue: {
      type: [String, Number],
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    divClass: {
      type: String,
      default: '',
    },
  },
  computed: {
    value: {
      get() {
        return this.modelValue
      },
      set(value) {
        this.$emit('change', value)
        this.$emit('update:modelValue', value)
      },
    },
  },
}
</script>

<template>
  <div class="form-content">
    <form class="form-signin">
      <div class="text-center mb-4">
        <img class="mb-4" src="../assets/images/logo.gif" alt="" />
        <h1 class="h3 mb-3 font-weight-bold text-primary">
          Dataseal Admin Login
        </h1>
      </div>
      <w-form-input
        v-model="values.email"
        name="email"
        type="email"
        placeholder="Email address"
        :error="errors.email"
        @validate="validate('email')"
        divClass="input-group mb-4"
      />
      <w-form-input
        v-model="values.password"
        name="password"
        type="password"
        placeholder="Password"
        :error="errors.password"
        @validate="validate('password')"
        divClass="input-group mb-4"
      />

      <div
        class="form-check d-flex justify-content-center align-items-center mb-4"
      >
        <input
          v-model="remember"
          class="form-check-input me-2"
          type="checkbox"
          value=""
          id="flexCheckDefault"
        />
        <label class="form-check-label mb-0" for="flexCheckDefault">
          Remember me
        </label>
      </div>
      <div v-if="failed" class="alert alert-danger my-4" role="alert">
        Invalid email or password
      </div>
      <button
        id="submitBtn"
        class="btn w-100 btn-lg btn-primary mb-3"
        type="submit"
        @click.prevent="handleLogin"
      >
        Sign in
      </button>
    </form>
  </div>
</template>

<script>
import yup, { LoginSchema } from '@/utils/yup'

export default {
  name: 'Login',
  setup() {
    return {
      schema: LoginSchema(),
    }
  },
  data() {
    return {
      values: {
        email: '',
        password: '',
      },
      errors: {
        email: '',
        password: '',
      },
      remember: false,
      failed: false,
    }
  },
  methods: {
    validate(field) {
      this.schema
        .validateAt(field, this.values)
        .then(() => {
          this.errors[field] = ''
        })
        .catch((err) => {
          this.errors[field] = err.message
        })
    },
    async handleLogin(e) {
      const ok = await this.$helper.validateYup(
        this.schema,
        this.values,
        this.errors
      )
      if (!ok) {
        return
      }
      try {
        this.$store.state.loadingPage = true
        const res = await this.$axios.post('auth/login', this.values)

        const jwt = res.data['token']
        this.$store.dispatch('login', jwt)
        this.$router.push({ name: 'index' })
      } catch (ex) {
        console.log('Failed login')
        this.$store.dispatch('logout')
        this.failed = true
      } finally {
        this.$store.state.loadingPage = false
      }
    },
  },
}
</script>

<style>
@import '../assets/styles/_login.scss';
</style>

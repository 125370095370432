<template>
  <div>
    <div class="row">
      <div class="col-12 card border-0 shadow">
        <div class="card-body">
          <CarouselForm />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CarouselForm from '@/components/forms/CarouselForm.vue'

export default {
  name: 'Komsol',
  components: { CarouselForm },
}
</script>

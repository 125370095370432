<template>
  <div>
    <app-form
      :url="url"
      :schema="schema"
      title="Article"
      ref="form"
      v-slot="{ values, errors, validate }"
    >
      <div class="row g-3">
        <div class="col-sm-6">
          <w-form-input
            name="title"
            label="Title"
            type="text"
            v-model="values.title"
            :error="errors.title"
            @validate="validate('title')"
          />
        </div>
        <div class="col-sm-2">
          <w-form-input
            name="group"
            label="Group"
            type="text"
            v-model="values.group"
            :error="errors.group"
            @validate="validate('group')"
          />
        </div>
        <div class="col-sm-2">
          <w-select
            name="inputLangauge"
            placeholder="Language"
            label="Language"
            :data="languages"
            v-model="values.langCode"
            :error="errors.langCode"
            @validate="validate('langCode')"
          />
        </div>
        <div class="col-sm-2">
          <w-form-input
            name="imageFolder"
            label="Image Folder"
            type="text"
            v-model="values.imageFolder"
            :error="errors.imageFolder"
            @validate="validate('imageFolder')"
          />
        </div>
        <div class="col-sm-4">
          <w-form-input
            name="ingress"
            label="Ingress"
            type="text"
            v-model="values.ingress"
            :error="errors.ingress"
            @validate="validate('ingress')"
          />
        </div>
        <div class="col-sm-4">
          <w-form-input
            name="metaWords"
            label="MetaKeyWords"
            type="text"
            v-model="values.metaWords"
            :error="errors.metaWords"
            @validate="validate('metaWords')"
          />
        </div>
        <div class="col-sm-4">
          <w-form-input
            name="metaDescr"
            label="MetaDescription"
            type="text"
            v-model="values.metaDescr"
            :error="errors.metaDescr"
            @validate="validate('metaDescr')"
          />
        </div>

        <div class="col-12">
          <app-article-editor ref="editor" v-model="values.text" />
        </div>
      </div>
    </app-form>
  </div>
</template>

<script>
import AppForm from '@/components/app/AppForm'
import wSelect from '@/components/wrappers/wSelect.vue'
import AppArticleEditor from '@/components/app/AppArticleEditor.vue'
import { ArticleSchema } from '@/utils/yup'

export default {
  name: 'ArticleForm',
  components: { wSelect, AppArticleEditor, AppForm },
  setup() {
    return {
      schema: ArticleSchema(),
    }
  },
  computed: {
    url() {
      return 'komsol/articles'
    },
    languages() {
      return this.$store.getters.komsol_langs
    },
    currentId() {
      return this.$route.params.id
    },
  },

  methods: {},
}
</script>

<template>
  <div>
    <app-form
      :url="url"
      :schema="schema"
      title="User"
      ref="form"
      v-slot="{ values, errors, validate }"
    >
      <div class="row g-3">
        <div class="col-sm-4">
          <w-form-input
            name="firstname"
            label="Firstname"
            type="text"
            v-model="values.firstname"
            :error="errors.firstname"
            @validate="validate('firstname')"
          />
        </div>
        <div class="col-sm-4">
          <w-form-input
            name="lastname"
            label="Lastname"
            type="text"
            v-model="values.lastname"
            :error="errors.lastname"
            @validate="validate('lastname')"
          />
        </div>
        <div class="col-sm-4">
          <w-form-input
            name="email"
            label="Email"
            type="text"
            v-model="values.email"
            :error="errors.email"
            @validate="validate('email')"
          />
        </div>
        <div class="col-sm-4">
          <w-form-input
            name="phoneNumber"
            label="PhoneNumber"
            type="text"
            v-model="values.phoneNumber"
            :error="errors.phoneNumber"
            @validate="validate('phoneNumber')"
          />
        </div>
        <div class="col-sm-4">
          <w-select
            name="inputOffice"
            placeholder="Office"
            label="Office"
            :data="offices"
            v-model="values.office"
            :error="errors.office"
            @validate="validate('office')"
          />
        </div>
        <div class="col-sm-4">
          <w-select
            name="inputRole"
            placeholder="Role"
            label="Role"
            :data="roles"
            v-model="values.role"
            :error="errors.role"
            @validate="validate('role')"
          />
        </div>
        <div class="col-sm-4">
          <w-form-input
            name="password"
            label="Password"
            type="password"
            v-model="values.password"
            :error="errors.password"
            @validate="validate('password')"
          />
        </div>
      </div>
    </app-form>
  </div>
</template>

<script>
import AppForm from '@/components/app/AppForm.vue'
import { UserSchema, PasswordField } from '@/utils/yup'

export default {
  name: 'UserForm',
  components: { AppForm },
  setup() {
    return {
      schema: UserSchema(),
    }
  },
  computed: {
    roles() {
      return this.$store.getters.roles
    },
    offices() {
      return this.$store.getters.offices
    },
    currentId() {
      return this.$route.params.id
    },
    url() {
      return 'users/'
    },
  },
}
</script>

import PrimeVue from 'primevue/config'
import Button from 'primevue/button'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import Toolbar from 'primevue/toolbar'
import ProgressBar from 'primevue/progressbar'
import OrderList from 'primevue/orderlist'
import ToastService from 'primevue/toastservice'
import Tree from 'primevue/tree'
import Dropdown from 'primevue/dropdown'
import Chip from 'primevue/chip'
import Breadcrumb from 'primevue/breadcrumb'

import wSelect from '@/components/wrappers/wSelect.vue'
import wStatus from '@/components/wrappers/wStatus.vue'
import wFormInput from '@/components/wrappers/wFormInput.vue'

export const registerPrimeVue = (app) => {
  app.use(PrimeVue)
  app.use(ToastService)
  app.component('Button', Button)
  app.component('InputText', InputText)
  app.component('DataTable', DataTable)
  app.component('Column', Column)
  app.component('Toolbar', Toolbar)
  app.component('ProgressBar', ProgressBar)
  app.component('OrderList', OrderList)
  app.component('Tree', Tree)
  app.component('Dropdown', Dropdown)
  app.component('Chip', Chip)
  app.component('Breadcrumb', Breadcrumb)

  app.component('wSelect', wSelect)
  app.component('wStatus', wStatus)
  app.component('wFormInput', wFormInput)
}

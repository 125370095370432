<template>
  <div :id="modalId" :ref="modalId" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <form>
          <div class="modal-header">
            <h4 class="modal-title">
              <span v-if="requestType === 'POST'">Add </span>
              <span v-else-if="requestType === 'PUT'">Edit </span>
              <span v-else>Invalid Request Type</span>
              {{ this.$helper.toName(this.title) }}
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <form v-if="formData">
              <div class="mb-3" v-for="(item, index) in fields" :key="index">
                <label class="col-form-label">
                  {{ this.$helper.toName(item.name) }}
                </label>
                <div v-if="item.type === 'dropdown'">
                  <select v-model="formData[item.name]" class="form-select">
                    <option
                      v-for="(option, index) in item.data"
                      :key="index"
                      :value="option.value"
                    >
                      {{ option.text }}
                    </option>
                  </select>
                </div>
                <div v-else-if="item.type === 'int'">
                  <input
                    v-model.number="formData[item.name]"
                    class="form-control"
                    :type="getInputType(item)"
                  />
                </div>
                <div v-else>
                  <input
                    v-model.number="formData[item.name]"
                    class="form-control"
                    :type="getInputType(item)"
                  />
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <input
              type="button"
              class="btn btn-default"
              @click="modal.hide()"
              value="Cancel"
            />
            <input
              type="submit"
              @click.prevent="handleSubmit"
              class="btn btn-success"
              value="Add"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import AppForm from '@/components/app/AppForm.vue'

export default {
  name: 'ModalForm',
  props: {
    modalId: String,
    title: String,
    fields: Array,
    url: String,
  },
  data() {
    return {
      formData: null,
      modal: null,
      requestType: 'POST',
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs[this.modalId])
  },

  computed: {},
  methods: {
    getInputType(item) {
      return item.type !== 'int' ? item.type : 'number'
    },
    resetForm() {
      const data = []
      for (const field of this.fields) {
        if (field.type === 'int') {
          data[field.name] = 0
        } else {
          data[field.name] = ''
        }
      }
      this.formData = Object.assign({}, data)
    },
    onOpen(newFormData) {
      this.requestType = newFormData ? 'PUT' : 'POST'
      this.modal.show()
      this.resetForm()
      if (newFormData) {
        this.formData = newFormData
      }
      console.log('open', this.formData)
    },
    async handleSubmit() {
      const payload = Object.assign({}, this.formData)
      if (this.requestType === 'POST') {
        console.log('POST', payload)
        const res = await this.$axios.post(this.url, payload)
      } else if (this.requestType === 'PUT') {
        const url = this.url + '/' + payload.id
        console.log('PUT', payload)
        const res = await this.$axios.put(url, payload)
      }
      this.resetForm()
      this.modal.hide()
      this.$emit('onClose')
    },
  },
}
</script>

<template>
  <div>
    <Toolbar class="p-mb-4">
      <template #start>
        <Button
          label="Create User"
          icon="pi pi-plus"
          class="p-button-success p-mr-2"
          @click="createItem()"
        />
      </template>
    </Toolbar>

    <DataTable
      dataKey="id"
      ref="dt"
      filterDisplay="menu"
      :value="items"
      :paginator="true"
      :rowHover="true"
      :rows="10"
      :totalRecords="totalRows"
      :lazy="true"
      v-model:filters="filters"
      @page="onPage($event)"
      @sort="onSort($event)"
      @filter="onFilter($event)"
    >
      <template #header>
        <div class="p-d-flex p-jc-between p-ai-center">
          <h5 class="p-m-0">Users</h5>
          <span class="p-input-icon-left">
            <i class="pi pi-search" />
            <InputText v-model="searchString" placeholder="Search" />
          </span>
        </div>
      </template>
      <template #empty> No users found. </template>
      <template #loading> Loading users data. Please wait. </template>

      <Column field="firstname" header="Firstname" ref="firstname" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Firstname"
          />
        </template>
      </Column>
      <Column field="lastname" header="Lastname" ref="lastname" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Lastname"
          />
        </template>
      </Column>
      <Column field="email" header="Email" ref="email" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Email"
          />
        </template>
      </Column>
      <Column field="office" header="Office" ref="office" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Office"
          />
        </template>
      </Column>
      <Column field="role" header="Role" ref="role" sortable>
        <template #filter="{ filterModel, filterCallback }">
          <InputText
            type="text"
            v-model="filterModel.value"
            @keydown.enter="filterCallback()"
            class="p-column-filter"
            placeholder="Search by Role"
          />
        </template>
      </Column>
      <Column field="phoneNumber" header="PhoneNumber" sortable></Column>
      <Column :exportable="false" style="min-width: 8rem">
        <template #body="slotProps">
          <Button
            icon="pi pi-pencil"
            class="p-button-rounded p-button-success p-mr-2"
            style="margin-right: 10px"
            @click="editItem(slotProps.data)"
          />
          <Button
            icon="pi pi-trash"
            class="p-button-rounded p-button-danger"
            @click="deleteItem(slotProps.data)"
          />
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script>
import { FilterMatchMode } from 'primevue/api'
export default {
  name: 'Users',
  components: {},
  data() {
    return {
      searchTimer: undefined,
      loading: true,
      searchString: null,
      items: [],
      totalRows: 0,

      filters: {
        firstname: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        lastname: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        email: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        office: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
        role: {
          constraints: [{ value: null, matchMode: FilterMatchMode.CONTAINS }],
        },
      },
      params: {},
    }
  },
  async mounted() {
    await this.getItems()
  },
  methods: {
    async getItems() {
      this.loading = true
      const res = await this.$axios.get('users', {
        params: this.getParamQuery(),
      })
      this.items = res.data.items
      this.totalRows = res.data.total
      this.loading = false
    },
    async onPage(event) {
      this.params = event
      await this.getItems()
    },
    async onSort(event) {
      this.params = event
      await this.getItems()
    },
    async onFilter() {
      this.params.filters = this.filters
      await this.getItems()
    },
    createItem() {
      this.$router.push({ name: 'create-user' })
    },
    editItem(item) {
      this.$router.push({ name: 'edit-user', params: { id: item.id } })
    },
    deleteItem(item) {},
    getParamQuery() {
      return {
        rows: this.params.rows,
        first: this.params.first,
        sortOrder: this.params.sortOrder,
        sortField: this.params.sortField,
        filters: this.filters,
        search: this.searchString,
      }
    },
  },
  watch: {
    searchString() {
      if (this.searchTimer) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(async () => {
        await this.getItems()
      }, 200)
    },
  },
}
</script>

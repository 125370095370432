<template>
  <div :id="modalId" :ref="modalId" class="modal fade">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{{ this.title }}</h4>
          <button
            type="button"
            class="btn-close"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>

        <div class="modal-body">
          <AppFileBrowser
            ref="filebrowser"
            @onFileSelected="loadDataFromSelected"
            hideDropdown
            hideDelete
            hideDownload
          />
          <hr />
          <div class="row g-3">
            <div v-if="isImage" class="col-1" style="margin-right: 10px">
              <div class="input-group mb-3">
                <button
                  @click="fitImage"
                  type="button"
                  class="btn btn-outline-primary"
                  style="width: 90px; min-width: 90px !important"
                >
                  Fit Image
                </button>
              </div>
              <div v-if="node" class="input-group mb-3">
                <button
                  @click="fitNode"
                  type="button"
                  class="btn btn-outline-primary"
                  style="width: 90px; min-width: 90px !important"
                >
                  Fit Html
                </button>
              </div>
            </div>
            <div v-if="isImage" class="col-2">
              <div class="input-group mb-3">
                <span class="input-group-text" id="addon-fb-width">Width</span>
                <input
                  id="imgWidthInput"
                  v-model="width"
                  type="number"
                  class="form-control"
                  aria-describedby="addon-fb-width"
                />
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="addon-fb-height"
                  >Height</span
                >
                <input
                  id="imgHeightInput"
                  v-model="height"
                  type="number"
                  class="form-control"
                  aria-describedby="addon-fb-height"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="input-group mb-3">
                <span class="input-group-text" id="addon-fb-id">Id</span>
                <input
                  id="imgIdInput"
                  v-model="id"
                  type="text"
                  class="form-control"
                  readonly
                  aria-describedby="addon-fb-id"
                />
              </div>
              <div class="input-group mb-3">
                <span class="input-group-text" id="addon-fb-filename">
                  Filename
                </span>
                <input
                  id="imgFilenameInput"
                  v-model="filename"
                  type="text"
                  class="form-control"
                  readonly
                  aria-describedby="addon-fb-filename"
                />
              </div>
            </div>
            <div v-if="!isImage" class="col-4">
              <div class="dropdown">
                <button
                  class="btn btn btn-outline-dark dropdown-toggle"
                  type="button"
                  id="dropdownMenuButton1"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <template v-if="icon.length > 0">
                    <img :src="$helper.imgSrc(this.icon, false)" />
                  </template>
                  <template v-else> Select Icon </template>
                </button>
                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                  <li>
                    <a class="dropdown-item" @click.prevent="selectIcon('')">
                      No icon
                    </a>
                  </li>
                  <li v-for="(item, index) in iconList" :key="index">
                    <a
                      class="dropdown-item"
                      @click.prevent="selectIcon(item.id)"
                    >
                      <img :src="$helper.imgSrc(item.id, false)" />
                      {{ item.filename }}
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <input
            type="button"
            class="btn btn-default"
            value="Cancel"
            @click="close"
          />
          <input
            type="button"
            class="btn btn-success"
            value="Select"
            @click.prevent="closeFile"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'
import AppFileBrowser from '../app/AppFileBrowser.vue'

export default {
  name: 'ModalBrowser',
  components: { AppFileBrowser },
  props: {
    modalId: String,
    text: String,
  },
  emits: ['close'],
  data() {
    return {
      modal: null,
      item: null,
      width: 0,
      height: 0,
      id: '',
      filename: '',
      icon: '',
      iconList: null,
      promise: null,
      promiseResolve: null,
      promiseReject: null,
      node: null,
      fileType: 'images',
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs[this.modalId])
  },
  computed: {
    isImage() {
      return this.fileType === 'images' ? true : false
    },
    title() {
      return this.isImage ? 'Image Browser' : 'File Browser'
    },
  },
  methods: {
    async getIcons() {
      const res = await this.$axios.get('filebrowser', {
        params: { image: true, folder: 'komsol/icons' },
      })
      this.iconList = res.data
      if (this.iconList && this.iconList.length > 0) {
        this.icon = this.iconList[0].id
      }
    },
    async loadDataFromNode() {
      const nodeName = this.node.nodeName.toLowerCase()

      if (!this.node || (nodeName !== 'img' && nodeName !== 'a')) {
        this.node = null
        this.resetOptions()
        return
      }

      this.width = this.node.width
      this.height = this.node.height

      const src = this.fileType === 'images' ? this.node.src : this.node.href
      const splitSrc = src.split('/')
      const id = splitSrc[splitSrc.length - 1]
      const res = await this.$axios.get('filebrowser', {
        params: { image: this.isImage, id: id },
      })
      const data = res.data

      if (!data || data.length === 0) {
        this.id = ''
        this.filename = ''
      } else {
        this.id = id
        this.filename = data[0].filename
        console.log('folder', data[0].folder)
        await this.$refs.filebrowser.selectFolder(data[0].folder)
        this.$refs.filebrowser.selectItemById(id)
      }
    },
    loadDataFromSelected(item) {
      this.item = item

      this.id = item.id
      this.filename = item.filename
      if (!this.node) {
        this.width = item.width
        this.height = item.height
      }
    },
    show(fileType) {
      this.fileType = fileType

      this.resetOptions()
      this.getIcons()
      this.$refs.filebrowser.selectedFileType = this.fileType
      this.loadDataFromNode()

      this.modal.show()
      this.$refs.filebrowser.refresh()
      this.promise = new Promise((resolve, reject) => {
        this.promiseResolve = resolve
        this.promiseReject = reject
      })
      return this.promise
    },
    closeFile() {
      if (this.isImage) {
        const res = { id: this.id, width: this.width, height: this.height }
        this.promiseResolve(res)
      } else {
        const res = { id: this.id, icon: this.icon, filename: this.filename }
        this.promiseResolve(res)
      }
      this.modal.hide()
    },
    close() {
      this.promiseResolve(null)
      this.modal.hide()
    },
    selectIcon(id) {
      this.icon = id
    },
    resetOptions() {
      this.id = ''
      this.filename = ''
      this.width = 0
      this.height = 0
    },
    fitImage() {
      this.width = this.item.width
      this.height = this.item.height
    },
    fitNode() {
      this.width = this.node.width
      this.height = this.node.height
    },
  },
  watch: {},
}
</script>

<style>
#fileContainer {
  margin-top: 20px;
  max-height: 450px;
}
#fileContainer figure:hover {
  opacity: 0.5;
}
#fileContainer .border:hover {
  border-color: darkgray !important;
}
</style>

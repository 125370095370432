<template>
  <div :id="modalId" :ref="modalId" class="modal fade">
    <div class="modal-dialog">
      <div class="modal-content">
        <form>
          <div class="modal-header">
            <h4 class="modal-title">
              Delete {{ this.$helper.toName(this.title) }}
            </h4>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <p>Are you sure you want to delete {{ this.count }} records?</p>
            <p class="text-warning">
              <small>This action cannot be undone.</small>
            </p>
          </div>
          <div class="modal-footer">
            <input
              type="button"
              class="btn btn-default"
              @click="modal.hide()"
              value="Cancel"
            />
            <input
              type="submit"
              @click.prevent="handleDelete"
              class="btn btn-danger"
              value="Delete"
            />
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import Modal from 'bootstrap/js/dist/modal'

export default {
  name: 'ModalDelete',
  props: {
    modalId: String,
    title: String,
    url: String,
  },
  data() {
    return {
      items: [],
      modal: null,
    }
  },
  mounted() {
    this.modal = new Modal(this.$refs[this.modalId])
  },
  computed: {
    count() {
      return this.items?.length
    },
  },
  methods: {
    onOpen(items) {
      this.modal.show()
      this.items = items
    },
    async handleDelete() {
      for (const item of this.items) {
        await this.$axios.delete(this.url + '/' + item.id)
      }
      this.modal.hide()
      this.$emit('onClose')
    },
  },
}
</script>
